<script>
import Swal from "sweetalert2";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
// import DisplayCmp from "./components/DisplayCmp";
// import Multiselect from "vue-multiselect";
// Dataset, DatasetItem, /* DatasetInfo,*/DatasetPager, /* DatasetSearch,*/ DatasetShow 
export default {
  props: { reloadOrders: {} , reloadOrdersDelete :{}
  },
  components: { Dataset, DatasetItem, /* DatasetInfo,*/DatasetPager, /* DatasetSearch,*/ DatasetShow, /* Multiselect */  },
  data() {
    return {
      tableData: [],
      requests: [],
      requestsFull: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      active : false,
      selectedRows:[],
      cols: [
         {
          name: "Référence ",
          field: "ref",
          sort: ''
        },
        {
          name: "Demandé par ",
          field: "requestedBy",
          sort: ''
        },
        {
          name: "Date de requête",
          field: "created_at",
          sort: ''
        },
        {
          name: "Status ",
          field: "statusPlain",
          sort: ''
        },
      ],
      excelheader:
         {
          "Référence "          : "ref",
          "Demandé par"         : "requestedBy",
          "Motif de la demande" : "reason",
          "Structure d'accueil" : "structure",
          "Imputation"          : "analytic_account",
          "Date d'arrivée"      : "date",
          "Date de sortie"      : "date_to",
          "Date de requête"     : "created_at",
          "Status"              : "status",
          "Supervisé par"       : "supervisor",
          "Approuvée par"       : "approvedBy",
        },
      selectedIndex:-1,
      drafts : [],
      pendings : [],
      confirms : [],
      valids : [],
      rejects : [],
      filterForm : {
        selectedBdv : [],
        etat : '',
        dateRange :'',
        analyticAccount:[]
      },
      etat : [],
      
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },

  },
  
  mounted() {
    this.totalRows = this.tableData.length;
    this.refreshVisitorSupportRequestsTable();
  },
  watch : {
    selectedRows(){
      if(this.selectedRows.length && undefined != this.selectedRows[0]){
        this.$emit('selectedOrders',this.selectedRows)
      }else{
        this.$emit('selectedOrders',[])
      }
    },
    
    reloadOrdersDelete:{
        handler(val){
          if(val){
            this.refreshVisitorSupportRequestsTable();
            this.selectedRows = []; this.selectedIndex = -1;
          }
        }
    },
    reloadOrders:{
        handler(val){
          if(val){
            this.selectedRows = []; this.selectedIndex = -1;
            this.refreshVisitorSupportRequestsTable();
          }
        }
      }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ListRequests(data){
      if(this.etat == data)
      this.resetFilter()
      else{
        this.etat            = data;
        this.filterForm.etat = data;
        if(data == 'draft') this.requests = this.drafts;
        else if(data == 'confirmed') this.requests = this.pendings;
        else if(data == 'confirmed') this.requests = this.confirms;
        else if(data == 'approved') this.requests = this.valids;
        else if(data == 'rejected') this.requests = this.rejects;
      }
      if(!this.active) this.active = !this.active
    },
    getFilteredList(){
      let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
      })
      this.$http
        .post("/ops/requests/visitors_support/filterdList",  {from: this.filterForm.dateRange.start,
          to: this.filterForm.dateRange.end, etat: this.filterForm.etat ,analyticAccount: this.filterForm.analyticAccount})
        .then((res) => {
          this.requests     = res.data.original.list;
          this.filterData();
          this.$emit('reloadOrdersDone',false);
          loader.hide();
        })
        .catch(() => {
          loader.hide();

          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    filterData(){
      this.drafts       = this.requests.filter(x => (x.statusPlain == 'draft'));
      this.pendings     = this.requests.filter(x => (x.statusPlain == 'confirmed'));
      this.confirms     = this.requests.filter(x => (x.statusPlain == 'confirmed'));
      this.valids       = this.requests.filter(x => (x.statusPlain == 'approved'));
      this.rejects      = this.requests.filter(x => (x.statusPlain == 'rejected'));
    },
    resetFilter(){
      this.selectedCTT                = [];
      this.filterForm.selectedBdv     = [];
      this.filterForm.dateRange       = [];
      this.filterForm.etat            = "";
      this.requests                   = this.requestsFull;
      this.filterForm.analyticAccount = "";
      this.etat                       = "";
      this.filterData();
    },
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
    refreshTable() {
    },
    deleteRequest(reqRef, reqUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la requête: " + reqRef + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/visitors_support/delete/" + reqUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  _this.refreshVisitorSupportRequestsTable();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    refreshVisitorSupportRequestsTable() {
      let loader = this.$loading.show({
                color: '#000000',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            })
      this.$http
        .post("/ops/requests/visitors_support/list")
        .then((res) => {
          this.requests     = res.data.original.list;
          this.requestsFull = this.requests;
          this.drafts       = this.requests.filter(x => (x.statusPlain == 'draft'));
          this.pendings     = this.requests.filter(x => (x.statusPlain == 'confirmed'));
          this.confirms     = this.requests.filter(x => (x.statusPlain == 'confirmed'));
          this.valids       = this.requests.filter(x => (x.statusPlain == 'approved'));
          this.rejects      = this.requests.filter(x => (x.statusPlain == 'rejected'));
          this.$emit('reloadOrdersDone',false);
          loader.hide();
        })
        .catch(() => {
          loader.hide();

          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style>
.here .multiselect__content-wrapper{
    max-height: 300px !important;
}
</style>
<template>

  <div class="row">
    <div class="col-12">
      <div class="accordion mb-2" role="tablist">
        <div class="row">
          <div class="col-sm-12 col-md-4 ">
            <div class="btn-group col-12">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'draft'}"
                  @click="ListRequests('draft')"
                >
                  <i class="fas fa-file-signature"></i> <span class="badge bg-secondary text-white">{{ drafts.length }}</span> Brouillon
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'confirmed'}"
                  @click="ListRequests('confirmed')"
                >
                  <i class="fas fa-check text-info"></i> <span class="badge bg-info text-white">{{ confirms.length }}</span> Confirmé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'approved'}"
                  @click="ListRequests('approved')"
                >
                  <i class="fas fa-check-double text-success"></i>
                  <span class="badge bg-success text-white ml-1"> {{ valids.length }}</span> Validé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'rejected'}"
                  @click="ListRequests('rejected')"
                >
                  <i class="fas fa-ban text-danger"></i>
                  <span class="badge bg-danger text-white ml-1"> {{ rejects.length }}</span> Rejeté
                </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
              <VueCtkDateTimePicker
                locale="fr"
                v-model="filterForm.dateRange"
                style="display:inline-block;margin-bottom: 20px;"
                :range="true"
                formatted="ll"
                color="#34495e"
                :no-label="true"
                :custom-shortcuts="customDateRangeShortcuts"
                :only-date="true"
                :auto-close="false"
              ></VueCtkDateTimePicker>
          </div>
          <div class="col-sm-12 col-md-3">
            <input type="text" placeholder="Imputation" v-model="filterForm.analyticAccount" class="form-control form-control-lg">
          </div>
          <div class="col-sm-6 col-md-2">
            <b-button  v-b-tooltip.hover.bottom="'Recherche'" variant="primary mr-4" @click="getFilteredList"> <i class="fas fa-search" ></i></b-button>
            <b-button  v-b-tooltip.hover.bottom="'Annuler'" variant="warning"  @click="resetFilter"> <i class="fas fa-times-circle"></i></b-button>
          </div>  
        </div>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
              <div class="row">
                <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des requets (Prise en charge )</div>
                <div class="col text-right">
                  <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                  <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                </div>
              </div>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
            <b-card-body class="shadow-lg">
              
            <div class="table-responsive mb-0 shadow">
              <dataset v-slot="{ ds }" :ds-data="requests" >
                <div class="row">
                  <div class="col-md-6 mb-2 mb-md-0">
                  </div>
                    <div class="col pr-5">
                      <download-excel 
                      :fields="excelheader"
                      style="cursor:pointer" 
                      class="float-right" 
                      worksheet="Liste des requets (Prise en charge )"
                      name="Liste_des_prise_en_charge.xls"
                      :data="requests">
                        <img width="40" :src="require('@/assets/images/base/excel.png')" />
                      </download-excel>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <table class="table table-hover d-md-table">
                        <thead>
                          <tr>
                            <th v-for="(th) in cols" :key="th.field">
                              {{ th.name }} 
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                              <td>{{ row.ref }}</td>
                              <td>{{ row.requestedBy }}</td>
                              <td>{{ row.created_at_format }}</td>
                              <td><span v-html="row.status"></span></td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                  <dataset-show :ds-show-entries="5" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>