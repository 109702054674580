<script>
import Swal from "sweetalert2";
import { required,email , maxLength,minLength,minValue,numeric,requiredIf } from "vuelidate/lib/validators";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import visitorSupportRequestsTable from "./components/visitorSupportRequestsTable";
import rejectionModal from "../../common/modals/rejectionModal";
import approvalModal from "./components/approveRequestModal";
import Multiselect from "vue-multiselect";
import SideButtons from '@/components/side-buttons.vue';
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { getDropDownElementsByTypeApi} from "@/api/common";
import {
  fetchCompanyListApi,
  fetchVisitorListByCompanyApi,
  fetchUserStructuresTypesApi,
  fetchHierarchysListApi,
  fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi,
  fetchSectionsListApi,
  fetchTakeAwayMealRequestDestinationsApi,
  fetchTakeAwayMealRequestExternalDestinationsApi,
  fetchMealsTypesApi,
  fetchIdentityDocTypesApi,
  fetchSupportTypesApi,
} from "@/api/common";

export default {
  page: {
    title: "Prise en charge",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, visitorSupportRequestsTable, vueDropzone: vue2Dropzone, rejectionModal, approvalModal ,Multiselect , SideButtons},
  import : {
    fetchCompanyListApi,
    fetchVisitorListByCompanyApi,
    fetchUserStructuresTypesApi,
    fetchHierarchysListApi,
    fetchDirectionsListApi,
    fetchDivisionsListApi,
    fetchDepartmentsListApi,
    fetchServicesListApi,
    fetchSectionsListApi,
    fetchTakeAwayMealRequestDestinationsApi,
    fetchTakeAwayMealRequestExternalDestinationsApi,
    fetchMealsTypesApi,
    fetchIdentityDocTypesApi,
    fetchSupportTypesApi
  },
  data() {
    return {
      dropzoneOptions: {
        url: process.env.VUE_APP_BASE_URL + "system/upload/processFileUpload",
        params: {
          attachmentType: "visitor_support_request",
          modalId: 1,
        },
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        // autoProcessQueue : false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.doc,.docx,.xls,.xlsx",
      },
      invoiceAttachment: false,
      title: "Prise en charge",
      items: [
        {
          text: "Catalogue des Services",
          to: {name:"service_catalog"},
        },
        {
          text: "Requêtes",
          to: {name:"requests"},
        },
        {
          text: "Prise en charge",
          active: true,
        },
      ],
      Company_info: {
        Company_name:'',
        Company_phone:'',
        Company_adr:'',
        comments:'',
      },
      Visitor_info: {
        Visitor_fullname:'',
        Visitor_identity_card_number:'',
        Visitor_function:'',
        Visitor_company_id:'',
        active: 1,
        nationality :''
      },
      addExtraModal:false,
      addVisitorModal:false,
      submitted:false,
      reloadOrders:undefined,
      reloadOrdersDelete : undefined,
      uuid: "",
      campsList: [],
      analyticList :[],
      externalDestinations: [],
      structureTypes: [],
      divisions: [],
      directions: [],
      departments: [],
      services: [],
      sections: [],
      structures: [],
      identityDocTypes: [],
      supportTypes: [],
      mealsTypes: [],
      submit: false,
      units: {},
      search: '',
      defaultShow: 5,
      visitorSupportData: {
        id: 0,
        ref: "",
        date: "",
        dateIn: "",
        dateOut: "",
        time_in: "",
        time_out: "",
        needHosting: "",
        needRest: "",
        needTransport: "",
        location_type: "",
        structure_type: "",
        structure: "",
        externalDestination:"",
        externalDestinationTxt:"",
        reason: "",
        analyticAccount: "",
        companyName: "",
        file: "",
        notes: "",
        status: "",
        statusPlain: "",
        maintenanceOrderRef: "",
        maintenanceOrder: [],
        modelType: "",
        rejectedBy: "",
        approvedBy: "",
        rejectionReason: "",
        rejectionDate: "",
        prestations: "",
        approvedAt: "",
        reception: [],
        entries : [],
        fileId : '',
        ov_file : [],
        is_visitor: "",
        needs_dr:false,
        visitors: [
          {
            full_name: "",
            identityDocType: "",
            identityDocNo: "",
            supportType: "",
            restaurationTypes : ""
          },
        ],
      },
    /*   visitorSupportData: {
        date: new Date().toISOString().substr(0, 10),
        dateIn: "",
        dateOut: "",
        needHosting: "",
        needRest: "",
        needTransport: "",
        location_type: "",
        structure_type: "",
        structure: "",
        externalDestination:"",
        externalDestinationTxt:"",
        reason: "",
        analyticAccount: "",
        companyName: "",
        file: "",
        notes: "",
        visitors: [
          {
            full_name: "",
            identityDocType: "",
            identityDocNo: "",
            supportType: "",
          },
        ],
      }, */
      destinationTypes : [
        {'id' : 'intra_company' ,'name': "SH Extra DP"},
        {'id' : 'external'  ,'name':'Extra SH'}
        ],
      restaurationTypes : [
        {'id' : 'vip' ,'name': "VIP"},
        {'id' : 'ordinary'  ,'name':'Ordinaire'}
        ],
      showForm : false,
      add:false,
      save:false,
      saveToPending:false,
      confirm:false,
      validate:false,
      edit:false,
      SaveEdit:false,
      destroy:false,
      send:false,
      disabled:false,
      resetForm:false,
      have:false,
      reject:false,
      reset:false,
      canEdit :true,
      list_visitor : [],
      List_Company : [],
      selectedList :[],
      companys : [],
      intraenprise : [],
      analyticListIntra : [],
      nationalitys : [],
    }
  },
  validations: {
    visitorSupportData: {
      date           : { required },
      dateIn         : { required },
      dateOut        : { required },
      needHosting    : { required: requiredIf(function() {
              return this.visitorSupportData.needRest == false
          }), },
      needRest       : { required: requiredIf(function() {
              return this.visitorSupportData.needHosting == false
          }), },
      structure_type : { required },
      structure      : { required },
      reason         : { required },
      analyticAccount: { required },
      companyName    : { required: requiredIf(function() {
              return this.visitorSupportData.location_type.id == "external"
          }), },
    },
    Company_info:{
      Company_name: { required },
      Company_phone: { required, numeric, maxLength:maxLength(14),minLength:minLength(9)},
      Company_adr: { required },
      //comments: { required },
    },
    Visitor_info:{
      Visitor_fullname:{ required },
      Visitor_identity_card_number:{ required },
      Visitor_function:{ required },
      Visitor_company_id:{ required },
      nationality:{ required },
    }
  },
  mounted() {
    this.fetchIntraEnpriseList();
    this.fetchCompanyList();
    this.fetchUserStructuresTypes();
    this.getHierarchysList();
    /* this.fetchDirectionsList();
    this.fetchDivisionsList();
    this.fetchDepartmentsList();
    this.fetchServicesList();
    this.fetchSectionList(); */
    this.fetchIdentityDocTypes();
    this.fetchSupportTypes();
    this.getNationalitys();
  },
  watch:{

    add:{
      handler(val){
        if(val){
          this.have = true;
          this.canEdit = true;
          this.showForm = true;
          this.resetForm = false;
          this.clearForms();
        }
      }
    },

    edit:{
      handler(val){
        if(val){
          this.have = true;
          this.canEdit = true;
          this.showForm = true;
          this.resetForm = false;
          // this.resetForm = false;
        }
      }
    },
    destroy:{
      handler(val){
        if(val){
         this.deleteRequest();
          this.resetForm = false;
          // this.resetForm = false;
        }
      }
    },
  },
  methods:{
    confirmRequest(){
      var contUid = this.visitorSupportData.uuid
      var contRef = this.visitorSupportData.ref
      var _this = this;
      if(contUid == "")
        this.$toast.error("Aucun élément n'est étais sélection");
      else if(!(this.visitorSupportData.statusPlain == 'draft'))
        this.$toast.error("Vous ne pouvez pas confirmé, car la demande est approuvée");
      else
      Swal.fire({
        title: "Êtes-vous sûr de Confirmé " + contRef + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#50a5f1",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/visitors_support/confirme/" + this.uuid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  // Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.reloadOrders = true;
                  this.visitorSupportData.status = '<label class="badge bg-primary">Confirmé</label>'
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  // Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    diffInDays(startDate, endDate) {
      const diffInMs = new Date(endDate) - new Date(startDate);
      return diffInMs / (1000 * 60 * 60 * 24);
    },
    afterComplete(file) {
      var res = JSON.parse(file.xhr.response);
      this.visitorSupportData.fileId = res.original.uploadedFile.uuid;
    },
    cancelBtn(){
      this.showForm  = false;
      this.have      = false;
      this.canEdit   = false;
      this.showForm  = false;
      this.resetForm = true;
    },
    clearForms(){
        this.visitorSupportData.id                       = 0;
        this.visitorSupportData.ref                      = "";
        this.visitorSupportData.date                     = "";
        this.visitorSupportData.dateIn                   = "";
        this.visitorSupportData.dateOut                  = "";        
        this.visitorSupportData.time_in                   = "";
        this.visitorSupportData.time_out                  = "";
        this.visitorSupportData.needHosting              = "";
        this.visitorSupportData.needRest                 = "";
        this.visitorSupportData.needTransport            = "";
        this.visitorSupportData.location_type            = "";
        this.visitorSupportData.structure_type           = "";
        this.visitorSupportData.structure                = "";
        this.visitorSupportData.externalDestination      = "";
        this.visitorSupportData.externalDestinationTxt   = "";
        this.visitorSupportData.reason                   = "";
        this.visitorSupportData.analyticAccount          = "";
        this.visitorSupportData.companyName              = "";
        this.visitorSupportData.file                     = "";
        this.visitorSupportData.notes                    = "";
        this.visitorSupportData.status                   = "";
        this.visitorSupportData.statusPlain              = "";
        this.visitorSupportData.maintenanceOrderRef      = "";
        this.visitorSupportData.maintenanceOrder         = [];
        this.visitorSupportData.modelType                = "";
        this.visitorSupportData.rejectedBy               = "";
        this.visitorSupportData.approvedBy               = "";
        this.visitorSupportData.rejectionReason          = "";
        this.visitorSupportData.rejectionDate            = "";
        this.visitorSupportData.prestations              = "";
        this.visitorSupportData.approvedAt               = "";
        this.visitorSupportData.reception                = [];
        this.visitorSupportData.entries                  = [];
        this.visitorSupportData.ov_file                  = [ ];
        this.visitorSupportData.visitors                 = [];
        this.visitorSupportData.visitors.full_name       = "";
        this.visitorSupportData.visitors.identityDocType = "";
        this.visitorSupportData.visitors.identityDocNo   = "";
        this.visitorSupportData.visitors.supportType     = "";
        this.visitorSupportData.visitors.restaurationTypes     = "";
        this.visitorSupportData.fileId      = "";
        this.$refs.myVueDropzone.removeAllFiles();
    },
     onchangeStructure(){
      var form = this.visitorSupportData;
      var struct = this.visitorSupportData.structure;
      form.analyticAccount = struct.analytic_account;
    },
    getImputation(){
      this.visitorSupportData.analyticAccount = '';
      
      this.analyticList                            = this.visitorSupportData.structure.analyticLists
      // if(this.visitorSupportData.location_type.id == 'internal')
        // this.visitorSupportData.analyticAccount =  this.visitorSupportData.structure.anaylticInternal
      // else
        // this.visitorSupportData.analyticAccount =  this.visitorSupportData.structure.anaylticExternal

    },
    onchangeStructureType(){
      var struct                              = this.visitorSupportData.structure_type.id;
      this.structures                         = [];
      this.visitorSupportData.structure       = "";
      this.visitorSupportData.analyticAccount = "";
      switch (struct) {
        case 'direction':
          this.structures = this.directions;
        break;

        case 'division':
          this.structures = this.divisions;
        break;

        case 'department':
          this.structures = this.departments;
        break;

        case 'service':
          this.structures = this.services;
        break;

        case 'section':
          this.structures = this.sections;
        break;
      
        default:
          
          break;
      }
    },
    _validateVisitorSupportVisitorsData(){
      var valid = true;
      var visitorsData = this.visitorSupportData.visitors;
      visitorsData.forEach((singleVisitorEntry, index) => {
        index++;
          if(singleVisitorEntry.full_name == ""){
            this.$toast.warning("Nom du visiteur est obligatoire dans la line N°: " + index);  
        valid = false;    
          }

          if(singleVisitorEntry.supportType == ""){
            this.$toast.warning("Type de prise en charge du visiteur est obligatoire dans la line N°: " + index);
        valid = false;
          }

   /*        if(index > 1 && valid){
            valid == true
          }else{
            valid = false
          } */
      });

      return valid;
    },
    AddformData() {
      if(this._validateVisitorSupportVisitorsData()){
        this.visitorSupportData.visitors.push({
          full_name: "",
          identityDocType: "",
          identityDocNo: "",
          supportType: "",
          restaurationTypes : ""
        });
      }
    },
    fetchCompanyList(){
      fetchCompanyListApi()
      .then(res =>{
        this.companys = res.data.list;
        //console.log(res);
      })
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
    
    fetchIntraEnpriseList(){
      this.$http.post('/referentiel/intra_entreprise/list')
      .then((res) => { this.intraenprise = res.data.list; } ) 
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

     getEmployees(){
      if(this.visitorSupportData.location_type.id == "intra_company"){
        this.List_Company =  this.intraenprise;
      }else{
        this.List_Company =  this.companys;
      }
      //console.log(this.companys)
    },

    getVisitor(){
      
      if(this.visitorSupportData.location_type.id == "external")
      fetchVisitorListByCompanyApi(this.visitorSupportData.companyName.id)
       .then(res => { 
        this.analyticListIntra = [];
        this.list_visitor      = res.data.original.list 
        this.analyticListIntra = this.visitorSupportData.companyName.analytics;
        
      })
      .catch(() => {

          // error.response.status Check status code
          
      }).finally(() => {

          //Perform action in always
      });
      else
      this.$http.post("/referentiel/employee_intra/listByStructure",{company_id : this.visitorSupportData.companyName.id})
      .then(res => { 
        this.analyticListIntra = [];
        this.list_visitor      = res.data.original.list 
        this.analyticListIntra = this.visitorSupportData.companyName.analytics;
        
      })
      .catch(() => {

          // error.response.status Check status code
          
      }).finally(() => {

          //Perform action in always
      });
    },
    /**
     * Delete the row
     */

    fetchIdentityDocTypes(){
      fetchIdentityDocTypesApi()
      .then((res) => (this.identityDocTypes = res.data.data))
              .catch(() => {})
              .finally(() => {});
    },
    fetchSupportTypes(){
      fetchSupportTypesApi()
      .then((res) => (this.supportTypes = res.data.data))
              .catch(() => {})
              .finally(() => {});   
    },
    fetchMealsTypes(){
      fetchMealsTypesApi()
      .then((res) => (this.mealsTypes = res.data.original.data))
              .catch(() => {})
              .finally(() => {});
    },
    getHierarchysList(){
       let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.2,
            zIndex: 999,
        })
      fetchHierarchysListApi()
        .then((res) => {
          this.directions  = res.data.directions
          this.divisions   = res.data.divisions
          this.departments = res.data.departements
          this.services    = res.data.service
          this.sections    = res.data.section
          
            loader.hide();
        })
        .catch(() => { loader.hide();})
        .finally(() => { loader.hide();});
    },
    fetchDirectionsList(){
      fetchDirectionsListApi()
        .then((res) => (this.directions = res.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDivisionsList(){
      fetchDivisionsListApi()
        .then((res) => (this.divisions = res.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDepartmentsList(){
      fetchDepartmentsListApi()
        .then((res) => (this.departments = res.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchServicesList(){
      fetchServicesListApi()
        .then((res) => (this.services = res.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchSectionList(){
      fetchSectionsListApi()
        .then((res) => (this.sections = res.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
        .then((res) => (this.structureTypes = res.data.data))
        .catch(() => {})
        .finally(() => {});
    },

    deleteUserFromList(value, id){
       this.list_visitor = this.list_visitor.filter(x => ( x.id != value.id));
    },
    deleteRow(index) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce visiteur?")){
        this.list_visitor.push(this.visitorSupportData.visitors[index].['full_name'])
        this.visitorSupportData.visitors.splice(index, 1);
      }
    },
    selectedOrders(selectedOrders){
      this.showForm = false;
      this.edit      = false;
      this.have      = false;
      this.canEdit   = true;
      this.clearForms();
      if(selectedOrders.length> 0 &&  undefined != selectedOrders[0]){
        this.have     = false;
        this.canEdit  = false;
        this.uuid = selectedOrders[0].uuid;
        this.supplier_id = selectedOrders[0].supplier_id;
        this.fetchSingleTakeawayRequestData();
      }
    },
    fetchSingleTakeawayRequestData() {
      var _this = this;
      let loader = this.$loading.show({
                color: '#000000',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            })
      this.$http
        .post("/ops/requests/visitors_support/getSingleRequestData/" + this.uuid)

        .then((res) => {
          var status = res.data.original.status;
          var tamr = res.data.original.data.tamr;
          switch (status) {
            case 200:
              _this.visitorSupportData.companyName         = tamr.company;
              _this.visitorSupportData.prestations         = tamr.prestations;
              _this.visitorSupportData.structure_type      = tamr.structureTypeObj;
              this.onchangeStructureType();
              _this.visitorSupportData.id                  = tamr.id;
              _this.visitorSupportData.ref                 = tamr.ref;
              _this.visitorSupportData.description         = tamr.description;
              _this.visitorSupportData.requestedBy         = tamr.requestedBy;
              _this.visitorSupportData.requestDate         = tamr.created_at;
              _this.visitorSupportData.requestDate_format  = tamr.created_at_format;
              _this.visitorSupportData.needHosting         = tamr.need_hosting;
              _this.visitorSupportData.needRest            = tamr.need_restauration;
              _this.visitorSupportData.needTransport       = tamr.need_transport;
              _this.visitorSupportData.requestType         = tamr.type;
              _this.visitorSupportData.requestLocationType = tamr.location;
              _this.visitorSupportData.location_type       = tamr.location_type;
              _this.visitorSupportData.location_txt        = tamr.location_txt;
              _this.visitorSupportData.requestUrgency      = tamr.urgency;
              _this.visitorSupportData.status              = tamr.status;
              _this.visitorSupportData.statusPlain         = tamr.statusPlain;
              _this.visitorSupportData.maintenanceOrder    = tamr.maintenanceOrder;
              _this.visitorSupportData.modelType           = tamr.modelType;
              _this.visitorSupportData.rejectedBy          = tamr.rejectedBy;
              _this.visitorSupportData.rejectionReason     = tamr.rejectionReason;
              _this.visitorSupportData.rejectionDate       = tamr.rejectionDate;
              _this.visitorSupportData.rejectionDate_format= tamr.rejectionDate_format;
              _this.visitorSupportData.approvedBy          = tamr.approvedBy;
              _this.visitorSupportData.approvedAt          = tamr.approvedAt;
              _this.visitorSupportData.approvedAt_format   = tamr.approvedAt_format;
              _this.visitorSupportData.entries             = tamr.entries;
              _this.visitorSupportData.reception           = tamr.reception;

              this.getVisitor();
             
              _this.visitorSupportData.reason              = tamr.reason;
              _this.visitorSupportData.structure           = tamr.structure ?  this.structures.filter(x => ( x.name == tamr.structure))[0] : '';
              _this.visitorSupportData.dateIn              = tamr.date_from;
              _this.visitorSupportData.dateIn_format       = tamr.date_from_format;
              _this.visitorSupportData.company             = tamr.company;
              _this.visitorSupportData.dateOut             = tamr.date_to;
              _this.visitorSupportData.dateOut_format      = tamr.date_to_format;
              _this.visitorSupportData.date                = tamr.date;         
              this.visitorSupportData.time_in              = tamr.time_in;
              this.visitorSupportData.time_out             = tamr.time_out;
              _this.visitorSupportData.ov_file             = tamr.ov_file;
              _this.visitorSupportData.notes               = tamr.notes;  
              _this.visitorSupportData.visitors            = tamr.entries;
              this.visitorSupportData.is_visitor           = tamr.is_visitor;
                  // setTimeout(function() {$('#nextBtn1').click(); }, 1000);

              this.analyticList                            = tamr.structure ? this.visitorSupportData.structure.analyticLists : [];

              if(this.visitorSupportData.is_visitor == 0 && this.visitorSupportData.location_type.id == "intra_company")
              this.analyticListIntra = tamr.company.analytics


              // this.visitorSupportData.structure = tamr.
              
              this.showForm = false;
              this.canEdit  = false;
              this.campsList = tamr.campsList;
              this.sleep(1000)
              this.visitorSupportData.analyticAccount = tamr.analytic_account; 
              

              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
          
          loader.hide();
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
          loader.hide();

        })
        .finally(() => {});
    },

    async sleep(ms){
      await new Promise(resolve => setTimeout(resolve, ms));
    },
    getVisitorAdd(item,index){
      // this.visitorSupportData.visitors = this.list_visitor.filter(x => ( x.full_name == item.full_name));

    },
    formSubmit() {	
      console.log(this.visitorSupportData)
      this.submitted = true;
      this.$v.visitorSupportData.$touch();
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      if(this.diffInDays(this.visitorSupportData.dateIn , this.visitorSupportData.dateOut ) < 0 || this.diffInDays(date , this.visitorSupportData.dateIn ) < 0 ){
        if(this.diffInDays(this.visitorSupportData.dateIn , this.visitorSupportData.dateOut ) < 0 ) this.$toast.warning("la date sortie doit être supérieur à la date de entrée");
        if(this.diffInDays(date , this.visitorSupportData.dateIn ) < 0 ) this.$toast.warning("la date d'entrée doit être supérieur à la date d'aujourd'hui");
      }else if(!this._validateVisitorSupportVisitorsData()){
        this.$toast.warning("Tu dois choisir ou moin un visiteur");
      }else if (!this.$v.visitorSupportData.$invalid ) {
        this.submitted = false;
        this.$http
          .post("/ops/requests/visitors_support/store",this.visitorSupportData)
          .then((res) => {
            var status = res.data.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.msg);
                this.reloadOrders = true;
                this.resetForm    = true;
                this.showForm = false;
                this.canEdit  = false;
                this.have     = false;
                break;

              case 500:
                this.$toast.warning(res.data.msg);
                break;
            }
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    deleteRequest(){
      this.destroy = false;
      var ref      = this.visitorSupportData.ref
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la requet  : " + ref + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/visitors_support/delete/" + this.uuid )
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.reloadOrdersDelete = true;
                  this.clearForms();
                  this.lastSelected   = null;
                  this.showForm = false;
                  this.canEdit  = false;
                  this.have     = false;
                  this.resetForm = true;

                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    validationByDR(){
      var contUid = this.visitorSupportData.uuid
      var contRef = this.visitorSupportData.ref
      Swal.fire({
        title: "Êtes-vous sûr de Valider " + contRef + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#50a5f1",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/ops/requests/visitors_support/dr_validation",this.visitorSupportData)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  // Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.reloadOrders = true;
                  this.visitorSupportData.status = '<label class="badge bg-primary">Confirmé</label>'
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  // Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    addExtra() {
       
      this.submitted = true;
      var valid = this.$v.Company_info.$invalid;
      this.$v.Company_info.$touch();
      // var validFormData = this._validateContractAmnts();

      if(!valid){
      this.submitted = false;

      this.$http
        .post("/referentiel/company_visitor/store", this.Company_info)

        .then((res) => {
          var status = res.data.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.msg);
              this.fetchCompanyList();
              this.addExtraModal= false
              this.$v.Company_info.$reset();
              this.visitorSupportData.companyName = res.data.intra;
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
    getNationalitys(){
         getDropDownElementsByTypeApi('country')
          .then((res) => {
              this.nationalitys = res.data.list;
              console.log(this.nationalitys)
            })
            .catch(() => {
              // error.response.status Check status code
            })
            .finally(() => {
              //Perform action in always
            });
     },

    addVisitor() {
      this.Visitor_info.Visitor_company_id = this.visitorSupportData.companyName.id;
      this.submitted = true;
      var valid = this.$v.Visitor_info.$invalid;
      this.$v.Visitor_info.$touch();

      if(!valid){
      this.submitted = false;

      this.$http
        .post("/referentiel/visitors_company/store", this.Visitor_info)

        .then((res) => {
          var status = res.data.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.msg);
              this.addVisitorModal = false
              this.getVisitor();
              this.visitorSupportData.visitors.push({
                  full_name: res.data.visitor,
                  identityDocType: "",
                  identityDocNo: "",
                  supportType: "",
                  restaurationTypes : ""
              })
              break;

            case 422:
              Swal.fire("Avertissement!", res.data.msg, "warning");
              break;
            case 500:
              Swal.fire("Avertissement!", res.data.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
    clearForm(){
      this.Company_info.uuid          = "";
      this.Company_info.Company_name  = "";
      this.Company_info.Company_phone = "";
      this.Company_info.Company_adr   = "";
      this.Company_info.comments      = "";
      this.addExtraModal = false;
        //this.Emp_info.Emp_mobile = this.selectedEmp.mobile;
        
    },
  }
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <visitor-support-requests-table :reloadOrders="reloadOrders" :reloadOrdersDelete="reloadOrdersDelete"  @selectedOrders="selectedOrders" @reloadOrdersDone="reloadOrders = $event" @reloadOrdersDeleteDone="reloadOrdersDelete = $event"/>
     <SideButtons  :reset="resetForm" :canEdit="canEdit || visitorSupportData.statusPlain  == 'approved'"
          :select="have" :save="send" :ODS="false" :service_order="false" @info ="info = $event" @add="add = $event" @save="save = $event" 
          @saveToPending="saveToPending = $event" @edit=" edit = $event" @SaveEdit="SaveEdit = $event" @confirm=" confirm = $event" 
          @reject="reject = $event" @validate=" validate = $event" @resetEmit="resetForm = $event" @delete=" destroy = $event" />
          
    <div v-show="showForm">
      <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
              <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-4"> 
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Motif de la demande *</label
                    >
                    <input
                      v-model="visitorSupportData.reason"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-2">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date *</label>
                    <input type="date" v-model="visitorSupportData.date" class="form-control" name="" id="">
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type de structure *</label>
                    <multiselect
                      v-model="visitorSupportData.structure_type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structureTypes"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      @input="onchangeStructureType"
                      :select-label="''"
                      :deselect-label="''"
                    >
                      <template slot="singleStruct" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Structure d'accueil *</label
                    >
                    <multiselect
                      v-model="visitorSupportData.structure"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structures"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      @close="getImputation"
                      :select-label="''"
                      :deselect-label="''"
                    >
                      <template slot="singleStruct" slot-scope="{ struct }">{{
                        struct.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                
                <div class="col-xs-12 col-md-4 col-lg-2">
                  <div class="form-group">
                    <label for="">Imputation *</label>
                    <!-- <input type="text" class="form-control"  v-model="visitorSupportData.analyticAccount"> -->
                    <select class="form-control" v-model="visitorSupportData.analyticAccount">
                      <option v-for="ana in analyticList" :key="ana.account" :value="ana.account"> {{ana.account}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-2">
                  <div class="form-group">
                    <label for="">Attachement </label>
                    <vue-dropzone
                    id="dropzone"
                    v-model="invoiceAttachment"
                    class="ebs-upload"
                    ref="myVueDropzone"
                    :use-custom-slot="true"
                    :options="dropzoneOptions"
                    @vdropzone-complete="afterComplete"
                  >
                    <div class="dropzone-custom-content">
                      <i class="display-4 text-muted fas fa-upload"></i>
                    </div>
                  </vue-dropzone>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-2">
                  <div class="form-group ">
                    <label for="formrow-password-input ">Date d'arrivée *</label>
                    <div class="row">
                    <input
                      v-model="visitorSupportData.dateIn"
                      type="date"
                      class="form-control col-xs-12 col-md-7"
                    />
                    <input type="time" v-model="visitorSupportData.time_in" class="form-control col-xs-12 col-md-4 ml-1" />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-2">
                  <div class="form-group">
                    <label for="">Date de sortie *</label>
                    <div class="row">
                    <input type="date" class="form-control col-xs-12 col-md-7" v-model="visitorSupportData.dateOut">
                    <input type="time" v-model="visitorSupportData.time_out" class="form-control col-xs-12 col-md-4 ml-1" />
                    </div>
                  </div>
                </div>
                <div class="form-group col-xs-12 col-md-4 col-lg-4">
                    <label for="">Services *</label> 
                    <div class="row justify-content-md-center">
                    <b-form-checkbox
                    switch
                    v-model="visitorSupportData.needRest"
                    class="inlb col-md-4"
                    >Restauration</b-form-checkbox
                    >
                    <b-form-checkbox
                    v-model="visitorSupportData.needHosting"
                    switch
                    class="inlb col-md-4"
                    >Hébergement</b-form-checkbox
                    >
                    <b-form-checkbox
                    v-model="visitorSupportData.needTransport"
                    switch
                    class="inlb col-md-3"
                    >Transport</b-form-checkbox
                    >
                    </div>
                </div>
              </div>
      
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Type *</label>
                    <multiselect
                      v-model="visitorSupportData.location_type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="destinationTypes"
                      :select-label="''"
                      :deselect-label="''"
                      @close="getEmployees"
                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-xs-12 col-md" v-if='visitorSupportData.location_type.id == "external"'>
                  <div class="form-group">
                    <label for="" class="d-flex justify-content-between"><span> Entreprise *</span> <small class="text-primary" @click="addExtraModal = true" style="cursor:pointer"><i class="fas fa-plus-circle"></i> Ajouter</small></label>
                    <multiselect
                      v-model="visitorSupportData.companyName"
                      :searchable="true"
                      track-by="id"
                      label="company_name"
                      :options="List_Company"
                      :select-label="''"
                      :deselect-label="''"
                      @close="getVisitor"
                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.company_name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-xs-12 col-md" v-show='visitorSupportData.location_type.id == "intra_company"'>
                  <div class="form-group">
                    <label for="">Structure *</label>
                    <multiselect
                      v-model="visitorSupportData.companyName"
                      :searchable="true"
                      track-by="id"
                      label="intra_entreprises_name"
                      :options="List_Company"
                      :select-label="''"
                      :deselect-label="''"
                      @close="getVisitor"
                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.intra_entreprises_name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-xs-12 col-md-2" v-show='visitorSupportData.location_type.id == "intra_company"'>
                  <div class="form-group">
                    <b-form-checkbox v-model="visitorSupportData.is_visitor" switch  :value="1" class="mb-3 mt-3">
                      Invite
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-xs-12 col-md" v-show='visitorSupportData.location_type.id == "intra_company" && visitorSupportData.is_visitor =="0" '>
                   <label for="">Imputation *</label>
                    <!-- <input type="text" class="form-control"  v-model="visitorSupportData.analyticAccount"> -->
                    <select class="form-control" v-model="visitorSupportData.analyticAccount">
                      <option v-for="ana in analyticListIntra" :key="ana.account" :value="ana.account"> {{ana.account}}</option>
                    </select>
                </div>
              </div>

            
            <div class="row d-flex align-items-center">
              <div class="col">
                <h3>Visiteurs: </h3>
              </div>
              <div class="col text-right">
                <!-- <a v-if="visitorSupportData.companyName.id" class="mt-3" @click="addVisitorModal = true" style="cursor:pointer"><i class="fas fa-plus-circle"></i> Ajouter</a> -->
              </div>
            </div>
             <hr>  
              <div>
                <div
                  v-for="(visitor, index) in visitorSupportData.visitors"
                  :key="visitor.id"
                  class="row"
                >
                <div class="form-group col-lg-3">
                    <label for="message">Nom complet</label>
                    <multiselect
                      :select-label="''"
                      :deselect-label="''"
                      @select="deleteUserFromList"
                      v-model="visitor.full_name"
                      :searchable="true"
                      track-by="id"
                      label="full_name"
                      :options="list_visitor"

                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ vis }">{{
                        vis.full_name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="form-group col-sm-12 col-md-2 col-lg-2">
                    <label for="resume">Pièce d'identité</label>
                    <multiselect
                      v-model="visitor.identityDocType"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="identityDocTypes"
                      :select-label="''"
                      :deselect-label="''"
                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group col-sm-2 col-md-2 col-lg-2">
                    <label for="subject">N° Identité</label>
                    <input
                      id="subject"
                      v-model="visitor.identityDocNo"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-sm-6 col-md-2 col-lg-2 ">
                    <label for="message">Type de prise en charge</label>
                    <multiselect
                      v-model="visitor.supportType"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :select-label="''"
                      :deselect-label="''"
                      :options="supportTypes"
                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="form-group col-sm-12 col-md-2 col-lg-2">
                    <label for="resume">Type de Restauration</label>
                    <multiselect
                      v-model="visitor.restaurationTypes"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="restaurationTypes"
                      :select-label="''"
                      :deselect-label="''"
                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="col-lg-1 align-self-center">
                    <a
                      v-if="
                        visitorSupportData.visitors.length > 1 &&
                        index != 0
                      "
                      href="javascript:void(0)"
                      ><i class="bx bx-trash-alt" @click="deleteRow(index)"></i
                    ></a>
                  </div>
                </div>
                <input
                  type="button"
                  class="btn btn-success mt-3 mt-lg-0"
                  value="Ajouter"
                  @click="AddformData"
                />
              </div>
              <br />
              <!-- <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-password-input">Attachement</label>
                    <input type="file" class="form-control" />
                  </div>
                </div>
              </div> -->

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="visitorSupportData.notes"
                      cols="30"
                      rows="1"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b-form-checkbox
                    v-model="visitorSupportData.needs_dr"
                    switch
                    class="inlb col-md-3"
                    >Requière Validation DR</b-form-checkbox
                    >
                </div>
              </div>

              <div>
                 <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                
                <button type="submit" :disabled="$v.visitorSupportData.$invalid" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-show="!showForm">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Détails
              <div class="button-items float-right mr-5" v-if="(visitorSupportData.statusPlain == 'draft' || visitorSupportData.statusPlain == 'confirmed') && $can('edit_vsr')">
              <b-button
                variant="success"  class="btn-label "
                @click="validationByDR"
                v-if="visitorSupportData.statusPlain == 'confirmed' && visitorSupportData.needs_dr  && $can('super_approve_vsr')"
              >
                <i
                  class="bx bx-check-double font-size-16 align-middle mr-2 text-white label-icon"
                ></i>
                Validation DR
              </b-button>
              <b-button
                variant="success"  class="btn-label "
                v-b-modal.approvalModal 
                v-if="(visitorSupportData.statusPlain == 'confirmed' && $can('approve_vsr') && !visitorSupportData.needs_dr) || (visitorSupportData.statusPlain == 'confirmed' && $can('approve_vsr') && visitorSupportData.needs_dr && visitorSupportData.dr_validation)"
              >
                <i
                  class="bx bx-check-double font-size-16 align-middle mr-2 text-white label-icon"
                ></i>
                Approuver
              </b-button>
              <b-button
                  variant="info btn-label"
                  @click="confirmRequest"
                  v-show="visitorSupportData.statusPlain == 'draft'  && $can('confirm_vsr')"
                >
                  <i
                    class="bx bx-check font-size-16 align-middle mr-2  label-icon"
                  ></i>
                  Confirmer
              </b-button>

              <b-button
                variant="danger" class="btn-label"
                v-b-modal.rejectionModal
                v-if="((visitorSupportData.statusPlain == 'draft' || visitorSupportData.statusPlain == 'confirmed') && $can('reject_vsr')) || (visitorSupportData.statusPlain == 'confirmed' && visitorSupportData.needs_dr  && $can('reject_vsr') && $can('super_approve_vsr'))"
              >
                <i class="bx bx-block font-size-16 align-middle mr-2 text-white label-icon"></i>
                Rejeter
              </b-button>
            </div>
            </h4>

            <p lass="text-muted mb-4">
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Demandeur :</th>
                        <td>{{ visitorSupportData.requestedBy }}</td>
                      </tr>
                      <tr>
                        <th>Structure d'accueil :</th>
                        <td>
                          {{ visitorSupportData.structure_type['name']}} : {{ visitorSupportData.structure['name'] }}
                        </td>
                      </tr>
                      <tr>
                        <th>Imputation :</th>
                        <td>
                          <b>{{ visitorSupportData.analyticAccount }}</b>
                        </td>
                      </tr>
                      <tr>
                        <th>Date d'arrivée :</th>
                        <td>
                          {{ visitorSupportData.dateIn_format }}
                          {{ visitorSupportData.time_in }}
                        </td>
                      </tr>
                      <tr>
                        <th>Motif de la demande :</th>
                        <td>
                          {{ visitorSupportData.reason }}
                        </td>
                      </tr>
                      <tr
                        v-if="(visitorSupportData.statusPlain == 'confirmed' || visitorSupportData.statusPlain == 'approved') && visitorSupportData.supervisor "
                      >
                        <th>Supervisé par  :</th>
                        <td>{{ visitorSupportData.supervisor }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-6">
                <div class="table-responsive mb-0">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Prestations :</th>
                        <td>
                          <span v-html="visitorSupportData.prestations"></span>
                        </td>
                      </tr>
                      <tr>
                        <th>Attachement :</th>
                        <td><a :href="visitorSupportData.ov_file.fullPath" target="_blank">{{visitorSupportData.ov_file.name}} <i class="fas fa-download"></i></a></td>
                      </tr>
                      <tr>
                        <th>Date de demande :</th>
                        <td>{{ visitorSupportData.requestDate_format }}</td>
                      </tr>
                      <tr>
                        <th>Date de sortie :</th>
                        <td>{{ visitorSupportData.dateOut_format }} {{ visitorSupportData.time_out }}</td>
                      </tr>
                      <tr>
                        <th>Etat :</th>
                        <td>
                          <span v-html="visitorSupportData.status"></span>
                        </td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'rejected'">
                        <th>Rejeté par :</th>
                        <td>{{ visitorSupportData.rejectedBy }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'rejected'">
                        <th>Date de rejet:</th>
                        <td>{{ visitorSupportData.rejectionDate_format }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'rejected'">
                        <th>Raison de rejet:</th>
                        <td>{{ visitorSupportData.rejectionReason }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'approved'">
                        <th>Approuvée Le :</th>
                        <td>{{ visitorSupportData.approvedAt_format }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'approved'">
                        <th>Approuvée par :</th>
                        <td>{{ visitorSupportData.approvedBy }}</td>
                      </tr>
                      <tr v-if="visitorSupportData.statusPlain == 'approved'">
                        <th>Bon de Livraison :</th>
                        <td>
                          
                            <router-link
                          :to="{
                            name: 'ops.receptions.display',
                            params: { uid: visitorSupportData.reception.uuid },
                          }"
                          >{{ visitorSupportData.reception.ref }}</router-link>
                          </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12">
                  <h4 class="card-title">Visiteurs: </h4> <br>
                  <h5 v-if='visitorSupportData.location_type.id == "external"'>Entreprise: {{ visitorSupportData.companyName['company_name'] }}</h5>
                  <h5 v-else>Structure : {{ visitorSupportData.companyName['intra_entreprises_name'] }}</h5>
                  <p class="card-title-desc">{{ visitorSupportData.notes }}</p>

                  <div class="table-responsive">
                    <table class="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Nom complet</th>
                          <th>N° Pièce d'identité</th>
                          <th>Type de prise en charge</th>
                          <th>Type de Restauration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="entry in visitorSupportData.entries" v-bind:key="entry.id">
                          <td>{{ entry.detailInfo }}</td>
                          <td>{{ entry.identityDocYype }} N°: <b>{{ entry.identityDocNo }}</b> </td>
                          <td>{{ entry.supportType.name }}</td>
                          <td>{{ entry.restaurationTypes.name }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <approval-modal :camps="campsList" :requestData="visitorSupportData" :modelUid="visitorSupportData.id" :modelRef="visitorSupportData.ref" />
    <rejection-modal
      :modelRef="visitorSupportData.ref"
      :modelId="visitorSupportData.id"
      :modelType="visitorSupportData.modelType"
    />
    <!-- Extra Modal -->
    <Modal
      v-model="addExtraModal"
      :title="`Ajouter Nouvelle Entreprise Extra SH`"
    >
      <fieldset>
            <form class="needs-validation" @submit.prevent="addExtra" enctype="multipart/form-data">
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="formrow-inputCity">Nom d'entreprise *</label>
                  <input type="text"  class="form-control" v-model="Company_info.Company_name" 
                    @blur="$v.Company_info.Company_name.$error"
                  :class="{
                    'is-invalid': submitted && $v.Company_info.Company_name.$error,
                  }" placeholder=""
                  />
                    <div
                      v-if="submitted && $v.Company_info.Company_name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.Company_info.Company_name.required"
                        >Le champ est obligatoire.</span
                      >
                    </div>
                </div>
              </div>

              <div class="col-lg-4">
                
                <div class="form-group">
                  <label for="formrow-inputCity"
                    >Télèphone *</label
                  >
                  <input type="text"  class="form-control" v-model="Company_info.Company_phone" 
                    @blur="$v.Company_info.Company_phone.$error"
                  :class="{
                    'is-invalid': submitted && $v.Company_info.Company_phone.$error,
                  }" placeholder=""
                  />
                  <div
                      v-if="submitted && $v.Company_info.Company_phone.$error"
                      class="invalid-feedback"
                    >
                        <span v-if="!$v.Company_info.Company_phone.required">
                        Le champ est obligatoire.</span><br>
                      <span v-if="!$v.Company_info.Company_phone.numeric"
                      >le Numréo de Télèphone doit étre un nombre
                      .</span>
                      <br>
                      <span v-if="!$v.Company_info.Company_phone.minLength"
                      >La taille minimum du champs est: 9 nombres
                      .</span>
                      <br>
                      <span v-if="!$v.Company_info.Company_phone.maxLength"
                      >La taille maximum du champs est: 10 nombre
                      .</span>
                    </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label for="formrow-password-input">Adresse</label>
                  <input type="text"  class="form-control" v-model="Company_info.Company_adr" placeholder=""
                    @blur="$v.Company_info.Company_adr.$error"
                  :class="{
                    'is-invalid': submitted && $v.Company_info.Company_adr.$error,
                  }">
                  <div
                      v-if="submitted && $v.Company_info.Company_adr.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.Company_info.Company_adr.required"
                        >Le champ est obligatoire.</span
                      >

                    </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires *</label>
                    <textarea
                      v-model="Company_info.comments"
                      class="form-control"
                      cols="30"
                      rows="1" 
                    ></textarea>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-12 ">
                <button type="button"  @click="clearForm" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                <button type="submit" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
              </div>
            </div>
          </form>
          </fieldset>
    </Modal>

    <!-- Visitor Modal -->
    <Modal
      v-model="addVisitorModal"
      :title="`Ajouter un nouveau visiteur à ${visitorSupportData.companyName.company_name}`"
    >
      <fieldset :disabled="disabled">
            <form class="needs-validation" @submit.prevent="addVisitor" enctype="multipart/form-data">
              <div class="row">
                
                <div class="col-xs-12 col-md-4 col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Nom de visiteur *</label>
    
                    <input type="text"  class="form-control" v-model="Visitor_info.Visitor_fullname" 
                      @blur="$v.Visitor_info.Visitor_fullname.$error"
                    :class="{
                      'is-invalid': submitted && $v.Visitor_info.Visitor_fullname.$error,
                    }" placeholder=""
                    />
                      <div
                        v-if="submitted && $v.Visitor_info.Visitor_fullname.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Visitor_info.Visitor_fullname.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-4">
                  
                  <div class="form-group">
                    <label for="formrow-inputCity"
                      >Numéro de carte d'identitie *</label
                    >
                    <input type="text"  class="form-control" v-model="Visitor_info.Visitor_identity_card_number" 
                      @blur="$v.Visitor_info.Visitor_identity_card_number.$error"
                    :class="{
                      'is-invalid': submitted && $v.Visitor_info.Visitor_identity_card_number.$error,
                    }" placeholder=""
                    />
                    <div
                        v-if="submitted && $v.Visitor_info.Visitor_identity_card_number.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Visitor_info.Visitor_identity_card_number.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                
                <div class="col-xs-12 col-md-4 col-lg-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Nationalité *</label>
                    <select v-model="Visitor_info.nationality" class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.Visitor_info.nationality.$error,
                        'isDisabled disabledBG':disabled
                      }">
                      <option v-for="nat in nationalitys" :key="nat.uuid" :value="nat.uuid"> {{nat.designation}} </option>
                    </select>
                    
                    <div v-if="submitted && $v.Visitor_info.nationality.$error"
                      class="invalid-feedback">
                      <span v-if="!$v.Visitor_info.nationality.required">
                        Le champ est obligatoire.</span>

                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-4">
                  <div class="form-group">
                    <label for="formrow-password-input">Fonction</label>
                    <input type="text"  class="form-control" v-model="Visitor_info.Visitor_function" placeholder=""
                      @blur="$v.Visitor_info.Visitor_function.$error"
                    :class="{
                      'is-invalid': submitted && $v.Visitor_info.Visitor_function.$error,
                    }">
                    <div
                        v-if="submitted && $v.Visitor_info.Visitor_function.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Visitor_info.Visitor_function.required"
                          >La champ est obligatoire.</span
                        >

                      </div>
                  </div>
                </div>
                <div class="col-xs-6 col-md-4 col-lg-2 mt-4">
                  <b-form-checkbox
                      v-model="Visitor_info.active"
                      switch  :value="1" checked="checked"
                      class="mb-3"
                      >Visiteur Active</b-form-checkbox
                    >
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <!-- <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                    <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                  </button> -->
                  <button type="button" class="btn btn-label btn-primary float-right" >
                    <i class="far fa-save label-icon "></i> Enregistrer
                  </button>
                </div>
              </div> 
            </form>
          </fieldset>
    </Modal>
    </div>
  </Layout>
</template>
<style >
  .dropzone {
    min-height: 5px;
    padding: 0px;
  }
</style>